import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import API_URL from '../../API_URL';

const ForgetPasswordForm = ({ setRedirect, setRedirectUrl, setRedirectParams, layout }) => {
  // State
  const [email, setEmail] = useState('');
  const [temporaryCode, setTemporaryCode] = useState('');
  const [loading, setLoading] = useState(false);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    if (email) {
      fetch(`${API_URL}/users/password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `userFromFront=${email}`
      })
        .then(response => response.json())
        .then(data => {
          if (data.result) {
            setTemporaryCode(data.temporaryCode);
            toast.success(`Un email a été envoyé à ${email} avec un lien de réinitialisation de mot de passe`);
            setLoading(false);
            setRedirect(true);
          } else {
            toast.error(data.error);
            setLoading(false);
          }
        })
        .catch(err => {
          console.log('err:', err);
        });
    }
  };

  useEffect(() => {
    setRedirectUrl(`/password-reset`);
  }, [setRedirectUrl, layout]);

  useEffect(() => {
    setRedirectParams({ email, temporaryCode });
  }, [email, temporaryCode]);

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          className="form-control"
          placeholder="Email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {loading ? (
          <Button color="primary" block className="mt-3" disabled>
            <Spinner type="grow" size="sm" color="light" />
          </Button>
        ) : (
          <Button color="primary" block disabled={!email}>
            Réinitialiser le mot de passe
          </Button>
        )}
      </FormGroup>
      {/* <Link className="fs--1 text-600" to="#!">
        Je ne peux pas me connecter en utilisant cette page
        <span className="d-inline-block ml-1">&rarr;</span>
      </Link> */}
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'basic' };

export default withRedirect(ForgetPasswordForm);
