import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, CustomInput, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import Divider from '../common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import withRedirect from '../../hoc/withRedirect';

import useGetCodesPartenaires from '../../utils/useGetCodesPartenaires';

/* FICHIER DANS GIT IGNORE */
import API_URL from '../../API_URL';

const RegistrationForm = ({ setRedirect, setRedirectUrl, setRedirectParams, layout, hasLabel }) => {
  const listeCodesPartenaires = useGetCodesPartenaires();

  // State
  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [codePartenaire, setCodePartenaire] = useState('');
  const [codePartenaireError, setCodePartenaireError] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    const isCodeOK = codePartenaire === '' ? true : listeCodesPartenaires.includes(codePartenaire);

    if (isCodeOK) {
      fetch(`${API_URL}/users/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `nomFromFront=${nom}&prenomFromFront=${prenom}&emailFromFront=${email}&passwordFromFront=${password}&codePartenaire=${codePartenaire}`
      })
        .then(response => response.json())
        .then(body => {
          if (body.result) {
            toast.success(`C'est parti ${prenom} ${nom} !`);
            setLoading(false);
            setRedirect(true);
          } else {
            setLoading(false);
            toast.error(body.error);
          }
        })
        .catch(err => {
          setLoading(false);
          console.log('erreur creation nouveau profil user', err);
        });
    } else {
      setCodePartenaireError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setRedirectUrl(`/confirm-email`);
    setRedirectParams({ params: email });
  }, [setRedirectUrl, layout, email]);

  useEffect(() => {
    setIsDisabled(!nom || !email || !password || !confirmPassword || !isAccepted || password !== confirmPassword);
  }, [nom, email, password, confirmPassword, isAccepted]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Prénom</Label>}
        <Input
          placeholder={!hasLabel ? 'Prénom' : ''}
          value={prenom}
          onChange={({ target }) => setPrenom(target.value)}
        />
      </FormGroup>

      <FormGroup>
        {hasLabel && <Label>Nom</Label>}
        <Input placeholder={!hasLabel ? 'Nom' : ''} value={nom} onChange={({ target }) => setNom(target.value)} />
      </FormGroup>

      <FormGroup>
        {hasLabel && <Label>Email</Label>}
        <Input
          placeholder={!hasLabel ? 'Email' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <div className="form-row">
        <FormGroup className="xs-col-12 md-col-6">
          {hasLabel && <Label>Mot de passe</Label>}
          <Input
            placeholder={!hasLabel ? 'Mot de passe' : ''}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            type="password"
          />
        </FormGroup>
        <FormGroup className="xs-col-12 md-col-6">
          {hasLabel && <Label>Confirmez le mot de passe</Label>}
          <Input
            placeholder={!hasLabel ? 'Confirmez le mot de passe' : ''}
            value={confirmPassword}
            onChange={({ target }) => setConfirmPassword(target.value)}
            type="password"
          />
        </FormGroup>
      </div>

      <CustomInput
        id="customCheckTerms"
        label={
          <Fragment>
            J'accepte{' '}
            <a href="https://inter-fast.fr/cgu.html" target="_blank">
              les conditions d'utilisation
            </a>{' '}
            et{' '}
            <a href="https://inter-fast.fr/confidential.html" target="_blank">
              la politique de confidentialité
            </a>
          </Fragment>
        }
        checked={isAccepted}
        onChange={({ target }) => setIsAccepted(target.checked)}
        type="checkbox"
      />
      <FormGroup className="xs-col-12 md-col-6" style={codePartenaireStyle}>
        {hasLabel && <Label style={{ color: 'var(--secondaryDark)' }}>Code partenaire</Label>}
        <Input
          placeholder={!hasLabel ? 'Entrez le code partenaire' : ''}
          value={codePartenaire}
          onChange={({ target }) => setCodePartenaire(target.value.toUpperCase())}
        />
        {codePartenaireError && (
          <p className="ml-3 mt-1 error fs--2 italic" style={{ color: 'var(--error)' }}>
            le code partenaire n'est pas valide
          </p>
        )}
      </FormGroup>
      <FormGroup>
        {loading ? (
          <Button color="primary" block className="mt-3" disabled>
            <Spinner type="grow" size="sm" color="light" />
          </Button>
        ) : (
          <Button color="primary" block className="mt-3" disabled={isDisabled}>
            S'inscrire
          </Button>
        )}
      </FormGroup>

      {/* Google Connect */}
      {/* <Divider className="mt-4">ou s'inscrire avec</Divider>
      <SocialAuthButtons /> */}
    </Form>
  );
};

RegistrationForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

RegistrationForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

const codePartenaireStyle = {
  marginTop: '2rem',
  padding: 8,
  borderRadius: '4px',
  border: '1px solid var(--secondary)',
  backgroundColor: 'var(--secondaryLight)'
};

export default withRedirect(RegistrationForm);
