import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

//stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  // 'pk_test_51HflTKB5pNogYwVO5QlZ1OYLXZNphfY2KdCRoFL9teWsZD2O4bZkJ5AYcCDVRAAhckSavqcIIFjNROjMDbZuAg4g00y3GSOnIL'
  'pk_live_51HflTKB5pNogYwVOFrrwfjwBpbrA3Yhv4z7fie1p9EnqQTD4H3v8YIluS6Ok6AgATxWS3aw7WMt0Wh3ezRhISTwl00qmddfqjF'
);

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Elements stripe={stripePromise}>
        <Layout />
      </Elements>
    </Router>
  );
};

export default App;
