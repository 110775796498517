import React, { useEffect, useState } from 'react';
import { Card, CardBody, Button, Input } from 'reactstrap';
import { Redirect } from 'react-router-dom';

import { toast } from 'react-toastify';
import FalconCardHeader from '../common/FalconCardHeader';
import TableClient from './tableClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import API_URL from '../../API_URL';
import ModalNewClient from './modalNewClient';

const ListeClient = props => {
  // State
  const [isSelected, setIsSelected] = useState(false);
  const [search, setSearch] = useState('');
  const [clientsBackup, setClientsBackup] = useState([]);
  const [clients, setClients] = useState([]);
  const [collapseOne, collapseOneOpen] = useState(false);

  useEffect(() => {
    const entrepriseID = JSON.parse(localStorage.getItem('entreprise'))._id;

    const getClients = async () => {
      await fetch(`${API_URL}/clients?entrepriseID=${entrepriseID}`)
        .then(response => response.json())
        .then(body => {
          setClients(body.clients);
          setClientsBackup(body.clients);
        })
        .catch(err => console.log('erreur fetch profil user', err));
    };
    getClients();
  }, []);

  const filterClient = searchString => {
    setSearch(searchString);
    const newListeClients = clientsBackup.filter(client => {
      const adressePrincipal = client.adresses.find(adresse => adresse.adressePrincipal == true);
      if (
        client.nomUser.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        client.telUser.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        adressePrincipal.adresse.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        adressePrincipal.codePostal.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        adressePrincipal.ville.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
      ) {
        return client;
      }
    });
    setClients(newListeClients);
  };

  const addClient = (typeClient, nomClient, adresseClient, cpClient, villeClient, emailClient, telClient) => {
    const entrepriseID = JSON.parse(localStorage.getItem('entreprise'))._id;

    fetch(`${API_URL}/nouveauClient`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `entrepriseID=${entrepriseID}&typeFromFront=${typeClient}&nomFromFront=${nomClient}&adresseFromFront=${adresseClient}&cpFromFront=${cpClient}&villeFromFront=${villeClient}&emailFromFront=${emailClient}&telFromFront=${telClient}`
    })
      .then(rawResponse => rawResponse.json())
      .then(response => {
        if (response.result) {
          fetch(`${API_URL}/clients?entrepriseID=${entrepriseID}`)
            .then(response => response.json())
            .then(body => {
              setClients(body.clients);
              toast.success('Votre nouveau client a bien été enregistré');
            })
            .catch(err => {
              console.log('erreur ajout nouveau client', err);
              toast.error("Le nouveau client n'a pas été enregistré");
            });
        }
      })
      .catch(err => {
        // setVisible(false);
        console.log('message : ', err.message);
      });
  };

  return (
    <Card className="mb-3">
      <ModalNewClient collapseOne={collapseOne} collapseOneOpen={collapseOneOpen} addClient={addClient} />
      <FalconCardHeader title="Mes clients" light={false} className="d-flex flex-column">
        <div className="d-flex">
          <Input
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input mr-4"
            style={{ width: '45%' }}
            value={search}
            onChange={({ target }) => filterClient(target.value)}
          />
          <Button className="btn-primary" onClick={() => collapseOneOpen(!collapseOne)}>
            <FontAwesomeIcon icon={'plus'} style={{ marginRight: 8 }} />
            Nouveau client
          </Button>
        </div>
      </FalconCardHeader>
      <CardBody className="p-0">
        <TableClient setIsSelected={setIsSelected} clients={clients} />
      </CardBody>
    </Card>
  );
};

export default ListeClient;
