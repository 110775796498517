export default {
  id: 'tony-robbins',
  name: 'Tony Robbins',
  email: 'tony@example.com',
  createdAt: 'Jan 12, 11:13 PM',
  description: null,
  vat_no: null,
  email_to: 'tony@example.com',
  address: '8962 Lafayette St.<br/>Oswego, NY 13126',
  cell: '+1-202-555-0110',
  invoice_prefix: '7C23435'
};
