import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

const withRedirect = OriginalComponent => {
  const UpdatedComponent = props => {
    // State
    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('/');
    const [redirectParams, setRedirectParams] = useState({});

    if (redirect) {
      return <Redirect to={{pathname: redirectUrl, state: redirectParams}} />;
    }

    return <OriginalComponent setRedirect={setRedirect} setRedirectUrl={setRedirectUrl} setRedirectParams={setRedirectParams} {...props} />;
  };

  return UpdatedComponent;
};

export default withRedirect;
