import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from '../../assets/img/illustrations/envelope.png';

const ConfirmMailContent = ({ email, layout, titleTag: TitleTag }) => (
  <Fragment>
    <img className="d-block mx-auto mb-4" src={envelope} alt="sent" width={70} />
    <TitleTag>Vérifiez vos email!</TitleTag>
    <p>
      Un email à été envoyé à <strong>{email}</strong>. Merci de cliquer sur le lien d'activation de votre compte avant
      de vous connecter.
    </p>
    <Button tag={Link} color="primary" size="sm" className="mt-3" to={`/`}>
      <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
      Se connecter
    </Button>
  </Fragment>
);

ConfirmMailContent.propTypes = {
  email: PropTypes.string.isRequired,
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

ConfirmMailContent.defaultProps = { layout: 'basic', titleTag: 'h4' };

export default ConfirmMailContent;
