import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '../LoginForm';

import AuthCardLayout from '../../../layouts/AuthCardLayout';

const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <Fragment>
          <p>
            Vous n'avez pas de compte?
            <br />
            <Link className="text-white text-underline" to="/signup">
              Inscrivez-vous maintenant!
            </Link>
          </p>
          <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
            Lire{' '}
            <a className="text-underline text-300" href="https://inter-fast.fr/cgu.html" target="_blank">
              nos conditions d'utilisation.
            </a>
          </p>
        </Fragment>
      }
    >
      <h3>Se connecter</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
