import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoutContent from '../LogoutContent';

import AuthCardLayout from '../../../layouts/AuthCardLayout';

const Logout = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <AuthCardLayout
      leftSideContent={
        <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
          Lire{' '}
          <a className="text-underline text-300" href="https://inter-fast.fr/cgu.html" target="_blank">
            nos conditions d'utilisation.
          </a>
        </p>
      }
    >
      <div className="text-center">
        <LogoutContent layout="card" titleTag="h3" />
      </div>
    </AuthCardLayout>
  );
};

export default Logout;
