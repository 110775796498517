import { useEffect, useState } from 'react';
import API_URL from '../API_URL';

export default function useGetCodesPartenaires() {
  const [listeCodesPartenaires, setListeCodesPartenaires] = useState([]);

  useEffect(() => {
    const getCodePartenaires = async () => {
      await fetch(`${API_URL}/partenaires/getCodesPartenaires`)
        .then(response => response.json())
        .then(data => data.result && setListeCodesPartenaires(data.listeCodesPartenaires))
        .catch(err => console.log('erreur de fetch codes Partenaires dans useGetCodePartenaires', err));
    };

    getCodePartenaires();
  }, []);

  return listeCodesPartenaires;
}
