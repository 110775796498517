import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, CardHeader, Row } from 'reactstrap';
import classNames from 'classnames';

const Title = ({ titleTag: TitleTag, titleStyle, className, breakPoint, children }) => (
  <TitleTag
    className={classNames(
      {
        'mb-0': !breakPoint,
        [`mb-${breakPoint}-0`]: !!breakPoint
      },
      className
    )}
    style={titleStyle}
  >
    {children}
  </TitleTag>
);

Title.propsType = {
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleStyle: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node
};

Title.defaultProps = { titleTag: 'h5', titleStyle: { color: 'var(--blanc)' } };

const FalconCardHeader = ({ title, light, titleTag, titleClass, className, breakPoint, children, headerStyle }) => (
  <CardHeader style={headerStyle} className={classNames({ 'bg-light': light }, className)}>
    {children ? (
      <Row className="align-items-center">
        <Col>
          <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
            {title}
          </Title>
        </Col>
        <Col
          {...{ [breakPoint ? breakPoint : 'xs']: 'auto' }}
          className={`text${breakPoint ? `-${breakPoint}` : ''}-right`}
        >
          {children}
        </Col>
      </Row>
    ) : (
      <Row>
        <Col>
          <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
            {title}
          </Title>
        </Col>
      </Row>
    )}
  </CardHeader>
);

FalconCardHeader.propTypes = {
  title: PropTypes.node.isRequired,
  light: PropTypes.bool,
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleClass: PropTypes.string,
  headerStyle: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  button: PropTypes.node
};

FalconCardHeader.defaultProps = { light: true, headerStyle: { backgroundColor: 'var(--primary)' } };

export default FalconCardHeader;
