import React, { useState } from 'react';
import { Row, Col, Button, CustomInput, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import loadable from '@loadable/component';

const ModalNewClient = ({ collapseOne, collapseOneOpen, addClient }) => {
  // State
  const [type, setType] = useState('default');
  const [typeError, setTypeError] = useState(false);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);

  const [street, setStreet] = useState('');
  const [streetError, setStreetError] = useState(false);

  const [zipCode, setZipCode] = useState('');
  const [zipCodeError, setZipCodeError] = useState(false);

  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);

  // Handlers

  const onSaveHandler = () => {
    let cpRegExp = RegExp('[0-9]{5}', 'g');
    let telRegExp = RegExp('[0-9]{10}', 'g');
    let emailRegExp = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'g');
    let emailTested = emailRegExp.test(email);
    let telTested = telRegExp.test(phone);
    let cpTested = cpRegExp.test(zipCode);
    if (type == 'default' || name == '' || !emailTested || !telTested || street == '' || !cpTested || city == '') {
      if (type == 'default') setTypeError(true);
      if (name == '') setNameError(true);
      if (!emailTested) setEmailError(true);
      if (!telTested) setPhoneError(true);
      if (street == '') setStreetError(true);
      if (!cpTested) setZipCodeError(true);
      if (city == '') setCityError(true);
    } else {
      addClient(type, name, street, zipCode, city, email, phone);
      setType('default');
      setTypeError(false);
      setName('');
      setNameError(false);
      setEmail('');
      setEmailError(false);
      setPhone('');
      setPhoneError(false);
      setStreet('');
      setStreetError(false);
      setZipCode('');
      setZipCodeError(false);
      setCity('');
      setCityError(false);
      collapseOneOpen(!collapseOne);
    }
  };
  return (
    <Modal isOpen={collapseOne} toggle={() => collapseOneOpen(!collapseOne)} centered={true} size="lg">
      <ModalHeader style={{ backgroundColor: 'var(--primary)' }}>
        <span style={{ color: 'var(--blanc)' }}>Nouveau Client</span>
      </ModalHeader>
      <ModalBody className="d-flex justify-content-between">
        <Row className="w-100">
          <Col sm={12} lg={6}>
            <span style={{ color: 'var(--primary)' }} className="mb-4">
              Informations
            </span>{' '}
            {/* Column Title */}
            {/* TYPE */}
            <div className="d-flex mt-4">
              {/* Row start */}
              <p className="mt-1">Type</p> {/* Row title */}
              <div className="ml-auto w-50">
                {' '}
                {/* Row Input + error */}
                <CustomInput
                  type="select"
                  id="type"
                  name="type"
                  value={type}
                  onChange={({ target }) => {
                    setType(target.value);
                    setTypeError(false);
                  }}
                >
                  <option value="default">Selectionnez un type</option>
                  <option value="particulier">Particulier</option>
                  <option value="societe">Société</option>
                </CustomInput>
                {typeError && (
                  <p className="ml-3" style={{ color: 'var(--error)' }}>
                    Selectionnez un type
                  </p>
                )}
              </div>{' '}
              {/* Row Input ends */}
            </div>{' '}
            {/* Row ends */}
            {/* NOM  */}
            <div className="d-flex">
              {' '}
              {/* Row starts */}
              <p className="mt-1">Nom - Raison sociale</p> {/* Row title */}
              <div className="ml-auto w-50">
                {' '}
                {/* Row Input + error */}
                <Input
                  placeholder="Prénom Nom"
                  value={name}
                  onChange={({ target }) => {
                    setName(target.value);
                    setNameError(false);
                  }}
                />
                {nameError && <p style={{ color: 'var(--error)' }}>Entrez un nom</p>}
              </div>{' '}
              {/* Row Input ends */}
            </div>{' '}
            {/* Row ends */}
            {/* EMAIL  */}
            <div className="d-flex mb-4">
              {' '}
              {/* Row starts */}
              <p className="mt-1">Email</p> {/* Row title */}
              <div className="ml-auto w-50">
                {' '}
                {/* Row Input + error */}
                <Input
                  placeholder="Email"
                  value={email}
                  onChange={({ target }) => {
                    setEmail(target.value);
                    setEmailError(false);
                  }}
                />
                {emailError && <p style={{ color: 'var(--error)' }}>Entrez un email</p>}
              </div>{' '}
              {/* Row Input ends */}
            </div>{' '}
            {/* Row ends */}
          </Col>
          <Col sm={12} lg={6}>
            <span style={{ color: 'var(--primary)' }}>Coordonnées</span>
            {/* STREET  */}
            <div className="d-flex mt-4">
              {' '}
              {/* Row starts */}
              <p className="mt-1">N° et nom de rue</p> {/* Row title */}
              <div className="ml-auto w-50">
                {' '}
                {/* Row Input + error */}
                <Input
                  placeholder="12 rue de l'église"
                  value={street}
                  onChange={({ target }) => {
                    setStreet(target.value);
                    setStreetError(false);
                  }}
                />
                {streetError && <p style={{ color: 'var(--error)' }}>Entrez un numéro et nom de rue</p>}
              </div>{' '}
              {/* Row Input ends */}
            </div>{' '}
            {/* Row ends */}
            {/* ZIPCODE */}
            <div className="d-flex">
              {' '}
              {/* Row starts */}
              <p className="mt-1">Code postal</p> {/* Row title */}
              <div className="ml-auto w-50">
                {' '}
                {/* Row Input + error */}
                <Input
                  placeholder="75000"
                  value={zipCode}
                  onChange={({ target }) => {
                    setZipCode(target.value);
                    setZipCodeError(false);
                  }}
                />
                {zipCodeError && <p style={{ color: 'var(--error)' }}>Entrez un code postal</p>}
              </div>{' '}
              {/* Row Input ends */}
            </div>{' '}
            {/* Row ends */}
            {/* CITY */}
            <div className="d-flex">
              {' '}
              {/* Row starts */}
              <p className="mt-1">Ville</p> {/* Row title */}
              <div className="ml-auto w-50">
                {' '}
                {/* Row Input + error */}
                <Input
                  placeholder="Paris"
                  value={city}
                  onChange={({ target }) => {
                    setCity(target.value);
                    setCityError(false);
                  }}
                />
                {cityError && <p style={{ color: 'var(--error)' }}>Entrez une ville</p>}
              </div>{' '}
              {/* Row Input ends */}
            </div>{' '}
            {/* Row ends */}
            {/* PHONE  */}
            <div className="d-flex">
              {' '}
              {/* Row starts */}
              <p className="mt-1">Téléphone</p> {/* Row title */}
              <div className="ml-auto w-50">
                {' '}
                {/* Row Input + error */}
                <Input
                  placeholder="0102030405"
                  value={phone}
                  onChange={({ target }) => {
                    setPhone(target.value);
                    setPhoneError(false);
                  }}
                />
                {phoneError && <p style={{ color: 'var(--error)' }}>Entrez un numéro de téléphone</p>}
              </div>{' '}
              {/* Row Input ends */}
            </div>{' '}
            {/* Row ends */}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            collapseOneOpen(!collapseOne);
            setType('default');
            setTypeError(false);
            setName('');
            setNameError(false);
            setEmail('');
            setEmailError(false);
            setPhone('');
            setPhoneError(false);
            setStreet('');
            setStreetError(false);
            setZipCode('');
            setZipCodeError(false);
            setCity('');
            setCityError(false);
          }}
        >
          Annuler
        </Button>
        <Button color="primary" onClick={() => onSaveHandler()}>
          Enregistrer
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalNewClient;
