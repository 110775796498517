import React from 'react';
import { Col, Row } from 'reactstrap';
import { version } from '../../config';

const Footer = () => (
  <footer>
    <Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          Copyright
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
          <a href="https://www.inter-fast.fr/" target="_blank" rel="noopener noreferrer">
            Interfast
          </a>{' '}
          <span className="d-none d-sm-inline-block">| Tous droits réservés. Une solution française fait avec ❤️</span>
        </p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600">v{version}</p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
