import { useEffect, useState } from 'react';

export default function useIsLogged(updateTrigger) {
  const [isLogged, setIslogged] = useState();

  useEffect(() => {
    const getToken = async () => {
      const token = await localStorage.getItem('token');
      token ? setIslogged(true) : setIslogged(false);
    };
    getToken();
  }, [updateTrigger]);

  // return false;
  return isLogged;
}
