import React, { Fragment, createRef, useState, useEffect } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import Badge from 'reactstrap/es/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { Link } from 'react-router-dom';

import purchases from '../../data/dashboard/purchaseList';
import { element } from 'prop-types';

const TableClient = ({ clients }) => {
  const clientFormatter = customerId => {
    let client = clients.find(client => client._id == customerId);
    return (
      <Link
        to={{
          pathname: '/client',
          state: { client: client }
        }}
      >
        {client.nomUser}
      </Link>
    );
  };
  const adresseFormatter = adresses => {
    let adresse = adresses.find(adresse => adresse.adressePrincipal == true);
    return (
      <span className="font-weight-semi-bold">
        {adresse.adresse}, {adresse.codePostal} {adresse.ville}
      </span>
    );
  };

  const columns = [
    {
      dataField: '_id',
      text: 'Client',
      formatter: clientFormatter,
      classes: 'border-0 align-middle primary',
      headerClasses: 'border-0'
    },
    {
      dataField: 'telUser',
      text: 'Téléphone',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true
    },
    {
      dataField: 'adresses',
      text: 'Adresse',
      formatter: adresseFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true
    }
  ];

  const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <input
        className="custom-control-input"
        {...rest}
        onChange={() => {}}
        ref={input => {
          if (input) input.indeterminate = indeterminate;
        }}
      />
      <label className="custom-control-label" />
    </div>
  );

  const options = {
    custom: true,
    sizePerPage: 999,
    totalSize: purchases.length
  };
  let table = createRef();

  // const handleNextPage = ({ page, onPageChange }) => () => {
  //   onPageChange(page + 1);
  // };

  // const handlePrevPage = ({ page, onPageChange }) => () => {
  //   onPageChange(page - 1);
  // };

  // const handleViewAll = ({ onSizePerPageChange }, newSizePerPage) => {
  //   onSizePerPageChange(newSizePerPage, 1);
  // };

  // const onSelect = (e) => {
  //     console.log(e);
  // };
  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    // onSelect: (row, isSelect, rowIndex, e) => {
    //   setClient(row);
    // },
    hideSelectColumn: true,
    style: { background: '#B5E0FA' }
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => {
        const lastIndex = paginationProps.page * paginationProps.sizePerPage;

        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="_id"
                data={clients}
                columns={columns}
                selectRow={selectRow}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                {...paginationTableProps}
              />
            </div>
            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                {/* <CustomTotal {...paginationProps} lastIndex={lastIndex} /> */}
                {/* <ButtonIcon
                  color="link"
                  size="sm"
                  icon="chevron-right"
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  className="px-0 font-weight-semi-bold"
                  onClick={() => handleViewAll(paginationProps, purchases.length)}
                >
                  view all
                </ButtonIcon> */}
              </Col>
              <Col xs="auto" className="pr-3">
                {/* <Button
                  color={paginationProps.page === 1 ? 'light' : 'primary'}
                  size="sm"
                  onClick={handlePrevPage(paginationProps)}
                  disabled={paginationProps.page === 1}
                  className="px-4"
                >
                  Previous
                </Button>
                <Button
                  color={lastIndex >= paginationProps.totalSize ? 'light' : 'primary'}
                  size="sm"
                  onClick={handleNextPage(paginationProps)}
                  disabled={lastIndex >= paginationProps.totalSize}
                  className="px-4 ml-2"
                >
                  Next
                </Button> */}
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

// TableClient.defaultProps = { layout: 'basic' };
export default TableClient;
