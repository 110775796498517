import React, { useContext } from 'react';
import PasswordResetForm from '../PasswordResetForm';
import AuthCardLayout from '../../../layouts/AuthCardLayout';
import { Redirect } from 'react-router-dom';
import AppContext from '../../../context/Context';

const PasswordReset = ({ location }) => {
  const { isDark } = useContext(AppContext);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const { email, temporaryCode } = location.state;

  return (
    <AuthCardLayout
      leftSideContent={
        <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
          Lire{' '}
          <a className="text-underline text-300" href="https://inter-fast.fr/cgu.html" target="_blank">
            nos conditions d'utilisation.
          </a>
        </p>
      }
    >
      {/* <h3>Réinitialiser votre mot de passe</h3> */}
      <PasswordResetForm layout="card" hasLabel email={email} temporaryCode={temporaryCode} />
    </AuthCardLayout>
  );
};

export default PasswordReset;
