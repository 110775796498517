import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, InputGroup, CustomInput, Input } from 'reactstrap';
import CountUp from 'react-countup';
import CardSummary from './CardSummary';
import ActiveUsersBarChart from './ActiveUsersBarChart';
import PaymentsLineChart from './PaymentsLineChart';
import { toast } from 'react-toastify';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ListeClient from '../client/listeClient';
import loadable from '@loadable/component';
import DashBoardDepositStatus from './DashboardDepositStatus';

const PurchasesTable = loadable(() => import('./PurchasesTable'));
const ActiveUsersMap = loadable(() => import('./ActiveUsersMap'));

const Dashboard = () => {
  // State
  const [isSelected, setIsSelected] = useState(false);

  return (
    <Fragment>
      <div>BIENVENUE SUR LA HOME</div>
      {/* <PaymentsLineChart /> */}
      {/* <DashBoardDepositStatus /> */}
      {/* <div className="card-deck">
        <CardSummary rate="-0.23%" title="Customers" color="warning" linkText="See all">
          58.39k
        </CardSummary>
        <CardSummary rate="0.0%" title="Orders" color="info" linkText="All orders">
          73.46k
        </CardSummary>
        <CardSummary content="43,594" rate="9.54%" title="Revenue" color="success" linkText="Statistics">
          <CountUp end={43594} duration={5} prefix="$" separator="," decimal="." />
        </CardSummary>
      </div>
      */}
      {/* <Card className="mb-3">
        <FalconCardHeader title="Mes clients" light={false} className='d-flex flex-column'>
            <div className="d-flex" >
              <Input
                type="search"
                placeholder="Search..."
                aria-label="Search"
                className="rounded-pill search-input mr-8"
                style={{width:'45%'}}
                // value={searchInputValue}
                // onChange={({ target }) => setSearchInputValue(target.value)}
                // onClick={() => setDropdownOpen(false)}
              />
              <Button outline color="primary" className="header-btn" >
                <FontAwesomeIcon 
                  icon={'plus'}
                  style={{marginRight:8}}
                />
                Nouveau client
              </Button>
            </div>
        </FalconCardHeader>
        <CardBody className="p-0">
          <PurchasesTable setIsSelected={setIsSelected} />
        </CardBody>
      </Card> */}
      {/* <Row noGutters>
        <Col lg="4" className="pr-lg-2">
          <ActiveUsersBarChart />
        </Col>
        <Col lg="8" className="pl-lg-2">
          <ActiveUsersMap />
        </Col>
      </Row> */}
      {/* <ListeClient /> */}
    </Fragment>
  );
};

export default Dashboard;
