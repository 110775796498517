import React, { useEffect, useState } from 'react';
import ConfirmPageContent from '../ConfirmPageContent';
import AuthCardLayout from '../../../layouts/AuthCardLayout';
import { Link } from 'react-router-dom';

import API_URL from '../../../API_URL';

const Confirmation = props => {
  const [confirmation, setConfirmation] = useState({});

  useEffect(() => {
    const checkConfirmation = async () => {
      await fetch(`${API_URL}/users${props.location.pathname}`)
        .then(response => response.json())
        .then(body => {
          setConfirmation(body);
        })
        .catch(err => console.log('erreur de confirmation signup', err));
    };
    checkConfirmation();
  }, []);

  return (
    <AuthCardLayout
      leftSideContent={
        <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
          Lire{' '}
          <a className="text-underline text-300" href="https://inter-fast.fr/cgu.html" target="_blank">
            nos conditions d'utilisation.
          </a>
        </p>
      }
    >
      <div className="text-center">
        <ConfirmPageContent
          layout="card"
          titleTag="h3"
          confirmed={confirmation.result}
          onTime={confirmation.onTime}
          email={confirmation.email}
          noUser={confirmation.noUser}
        />
      </div>
    </AuthCardLayout>
  );
};

export default Confirmation;
