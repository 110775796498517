import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import Login from '../components/auth/card/Login';
import Signup from '../components/auth/card/Registration';
import Confirm from '../components/auth/card/ConfirmMail';
import Confirmation from '../components/auth/card/ConfirmationPage';
import Logout from '../components/auth/card/Logout';
import ForgetPassword from '../components/auth/card/ForgetPassword';
import PasswordReset from '../components/auth/card/PasswordReset';
import Dashboard from '../components/dashboard/Dashboard';
import CustomerDetails from '../components/page/CustomerDetails';

import loadable from '@loadable/component';

// const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
// const Landing = loadable(() => import('../components/landing/Landing'));
// const WizardLayout = loadable(() => import('../components/auth/wizard/WizardLayout'));
// const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));
// const AuthSplitRoutes = loadable(() => import('../components/auth/split/AuthSplitRoutes'));

const Layout = () => {
  // useEffect(() => {
  //   AuthBasicLayout.preload();
  //   Landing.preload();
  //   WizardLayout.preload();
  //   AuthCardRoutes.preload();
  //   AuthSplitRoutes.preload();

  // }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        {/* <Route path="/landing" exact component={Landing} /> */}
        {/* <Route path="/authentication/card" component={AuthCardRoutes} /> */}
        {/* <Route path="/authentication/split" component={AuthSplitRoutes} /> */}
        {/* <Route path="/authentication/wizard" component={WizardLayout} /> */}
        <Route path="/" exact component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forget-password" component={ForgetPassword} />
        <Route path="/password-reset" component={PasswordReset} />
        <Route path="/confirm-email" component={Confirm} />
        <Route path="/confirmation" component={Confirmation} />
        <Route path="/logout" component={Logout} />
        <Route component={DashboardLayout} />

        <Route path="/errors" component={ErrorLayout} />
        {/* <Route path="/authentication/basic" component={AuthBasicLayout} /> */}
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
