import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from '../../assets/img/illustrations/envelope.png';

const ConfirmPageContent = ({ confirmed, onTime, email, noUser, titleTag: TitleTag }) => {
  return (
    <Fragment>
      {!!onTime && (
        <>
          <FontAwesomeIcon icon="check-square" color="var(--secondary)" transform="grow-48" className="my-5" />
          <TitleTag>Merci d'avoir activé votre compte Interfast!</TitleTag>
          <p>Vous pouvez vous connecter et commencer à gagner du temps grâce à vos nouveaux outils !</p>
          <Button tag={Link} color="primary" size="sm" className="mt-3" to={`/`}>
            <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
            Se connecter
          </Button>
        </>
      )}

      {!!noUser && (
        <>
          <FontAwesomeIcon icon="times" color="var(--error)" transform="grow-48" className="my-5" />
          <TitleTag>Il semble que vous ne vous soyez pas inscrit...</TitleTag>
          <p>Mais heureusement, il est toujours temps de le faire !</p>
          <Button tag={Link} color="primary" size="sm" className="mt-3" to={`/signup`}>
            <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
            S'inscrire
          </Button>
        </>
      )}

      {!confirmed && !noUser && (
        <>
          <FontAwesomeIcon icon="times" color="var(--error)" transform="grow-48" className="my-5" />
          <TitleTag>Ce lien d'activation n'est plus valide !</TitleTag>
          <p>Mais ne vous inquietez pas, vous pouvez en générer un nouveau en un clic !</p>
          <Button
            tag={Link}
            color="primary"
            size="sm"
            className="mt-3"
            to={`/confirm-email?resend=true&email=${email}`}
          >
            <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
            Renvoyez-moi un nouveau lien
          </Button>
        </>
      )}
    </Fragment>
  );
};

ConfirmPageContent.propTypes = {
  email: PropTypes.string.isRequired,
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

ConfirmPageContent.defaultProps = { layout: 'basic', titleTag: 'h4' };

export default ConfirmPageContent;
