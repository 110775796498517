import React from 'react';
import ForgetPasswordForm from '../ForgetPasswordForm';
import AuthCardLayout from '../../../layouts/AuthCardLayout';
import { Link } from 'react-router-dom';

const ForgetPassword = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
          Lire{' '}
          <a className="text-underline text-300" href="https://inter-fast.fr/cgu.html" target="_blank">
            nos conditions d'utilisation.
          </a>
        </p>
      }
    >
      <h4 className="mb-0">Vous avez oublié votre mot de passe?</h4>
      <p className="mb-0">Saisissez votre email et nous vous enverrons un lien de réinitialisation.</p>
      <ForgetPasswordForm layout="card" />
    </AuthCardLayout>
  );
};

export default ForgetPassword;
