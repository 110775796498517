import React, { useEffect } from 'react';
import ConfirmMailContent from '../ConfirmMailContent';
import AuthCardLayout from '../../../layouts/AuthCardLayout';
import { Link } from 'react-router-dom';

import API_URL from '../../../API_URL';

const ConfirmMail = props => {
  let search = new URLSearchParams(props.location.search);
  let resend;
  let email;
  if (search) {
    resend = search.get('resend');
    email = search.get('email');
  }
  // let resend = search.get('resend');
  // let email = search.get('email');

  const emailUser = resend ? email : props.location.state.params;
  const url = `${API_URL}/users/confirmMail?resend=${resend}&emailUser=${emailUser}`;

  useEffect(() => {
    const sendConfirmationMail = async () => {
      await fetch(url)
        .then(response => response.json())
        .then(body => {
          console.log('confirmation mail OK');
        })
        .catch(err => {
          console.log('erreur dans le fetch confirmation email:', err);
        });
    };
    sendConfirmationMail();
  }, []);
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
          Lire{' '}
          <a className="text-underline text-300" href="https://inter-fast.fr/cgu.html" target="_blank">
            nos conditions d'utilisation.
          </a>
        </p>
      }
    >
      <div className="text-center">
        <ConfirmMailContent layout="card" email={emailUser} titleTag="h3" />
      </div>
    </AuthCardLayout>
  );
};

export default ConfirmMail;
