import React from 'react';
import { Button } from 'reactstrap';
import RegistrationForm from '../RegistrationForm';
import AuthCardLayout from '../../../layouts/AuthCardLayout';
import { Link } from 'react-router-dom';

const Registration = () => (
  <AuthCardLayout
    leftSideContent={
      <p className="pt-3">
        Vous avez un compte?
        <br />
        <Button tag={Link} color="outline-light" className="mt-2 px-4" to="/">
          Se connecter
        </Button>
      </p>
    }
  >
    <h3>S'inscrire</h3>
    <RegistrationForm layout="card" hasLabel />
  </AuthCardLayout>
);

export default Registration;
