import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import ProductProvider from '../components/e-commerce/ProductProvider';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import { getPageName } from '../helpers/utils';
import Login from '../components/auth/card/Login';
import CustomerDetails from '../components/page/CustomerDetails';

import useIsLogged from '../utils/useIsLogged';

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = ({ location }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  const isKanban = getPageName('kanban');

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const isLogged = useIsLogged();

  // Code Crisp
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '235e4425-2c1d-493e-9a5e-fee77def0587';

    (function() {
      var d = document;
      var s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }, []);

  if (isLogged === false) {
    return <Redirect to="/" />;
  }

  return (
    // <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
    <div className="container">
      <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />
      <ProductProvider>
        <div className="content">
          <NavbarTop />
          <Switch>
            <Route path="/home-partenaires" exact component={Dashboard} />
            {/* <Route path="/dashboard-alt" exact component={DashboardAlt} /> */}
            <DashboardRoutes />
          </Switch>
          <Footer />
        </div>
        {/* <SidePanelModal path={location.pathname} /> */}
      </ProductProvider>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
