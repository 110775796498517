import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import team3 from '../../assets/img/team/3.jpg';
import Avatar from '../common/Avatar';

const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const profilPicture = localStorage.getItem('profilPicture')?.replace(/"/g, '');

  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar src={profilPicture} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem className="font-weight-bold">
            {user.prenomUser} {user.nomUser}
          </DropdownItem>
          <DropdownItem divider />

          <DropdownItem
            className="font-weight-bold text-warning"
            tag={Link}
            to="/mon-offre"
            // href="https://inter-fast.fr/pricing.html"
            // target="_blank"
          >
            <FontAwesomeIcon icon="crown" className="mr-1" />
            <span>Voir les tarifs</span>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/mon-compte">
            Mon compte
          </DropdownItem>
          <DropdownItem tag={Link} to="/mon-offre">
            Mon offre
          </DropdownItem>
          <DropdownItem href="https://tally.so/r/mK5Wkw" target="_blank">
            Donnez votre avis
          </DropdownItem>
          <DropdownItem divider />
          {/* <DropdownItem tag={Link} to="/pages/settings">
            Settings
          </DropdownItem> */}
          <DropdownItem tag={Link} to="/logout">
            Se déconnecter
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
