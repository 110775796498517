import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Label, Spinner } from 'reactstrap';
import Loader from '../common/Loader';

import withRedirect from '../../hoc/withRedirect';

// FICHIER DANS GIT IGNORE
import API_URL from '../../API_URL';

const LoginForm = ({ setRedirect, hasLabel, layout, setRedirectUrl }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem('token'));
  }, []);

  useEffect(() => {
    setRedirectUrl(`/home`);
  }, [setRedirectUrl, layout]);

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);
    fetch(`${API_URL}/users/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `userFromFront=${email}&passwordFromFront=${password}`
    })
      .then(response => response.json())
      .then(body => {
        if (body.result) {
          localStorage.setItem('user', JSON.stringify(body.user));
          localStorage.setItem('nomUser', JSON.stringify(body.user.nomUser));
          localStorage.setItem('prenomUser', JSON.stringify(body.user.prenomUser));
          localStorage.setItem('userID', JSON.stringify(body.user._id));
          localStorage.setItem('entreprise', JSON.stringify(body.entreprise));
          localStorage.setItem('entrepriseID', JSON.stringify(body.user.entreprise));
          localStorage.setItem('token', JSON.stringify(body.user.token));
          localStorage.setItem('userRole', JSON.stringify(body.user.roleUser));
          localStorage.setItem('profilPicture', JSON.stringify(body.user.profilPicture));
          toast.success(`${body.user.prenomUser} ${body.user.nomUser} connecté !`);
          setLoading(false);
          setRedirect(true);
        } else {
          setLoading(false);
          toast.error(body.error);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log('erreur fetch profil user', err);
      });
  };

  if (token) {
    // toast.success(`${body.user.prenomUser} ${body.user.nomUser} connecté !`);
    // setToken(null);
    setRedirect(true);
  }
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Email</Label>}
        <Input
          placeholder={!hasLabel ? 'Email' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Mot de passe</Label>}
        <Input
          placeholder={!hasLabel ? 'Mot de passe' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Se souvenir de moi"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
        <Col xs="auto">
          <Link className="fs--1" to="/forget-password">
            Mot de passe oublié?
          </Link>
        </Col>
      </Row>
      <FormGroup>
        {loading ? (
          <Button color="primary" block className="mt-3" disabled>
            <Spinner type="grow" size="sm" color="light" />
          </Button>
        ) : (
          <Button color="primary" block className="mt-3" disabled={isDisabled}>
            Se connecter
          </Button>
        )}
      </FormGroup>

      {/* Google Connect */}
      {/* <Divider className="mt-4">ou se connecter avec </Divider>
    <SocialAuthButtons /> */}
    </Form>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(LoginForm);
