import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import Label from 'reactstrap/es/Label';
import classNames from 'classnames';

import API_URL from '../../API_URL';

const PasswordResetForm = ({ setRedirect, setRedirectUrl, hasLabel, email, temporaryCode }) => {
  // State
  const [userCode, setUserCode] = useState('');
  const [isCodeOK, setIsCodeOK] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isCodeDisabled, setIsCodeDisabled] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    !email && setRedirect(true);
  }, []);

  // Handler
  const handleTestCode = e => {
    e.preventDefault();
    if (temporaryCode === userCode) {
      toast.success('Le code confidentiel est correct !');
      setIsCodeOK(true);
    } else {
      toast.error('Le code confidentiel est incorrect !');
    }
  };

  const handleReset = async e => {
    e.preventDefault();
    setLoading(true);
    toast.success('Connectez-vous avec votre nouveau mot de passe');

    await fetch(`${API_URL}/users/password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `userFromFront=${email}&passwordFromFront=${password}`
    })
      .then(response => response.json())
      .then(data => {
        if (data.result) {
          setLoading(false);
          setRedirect(true);
        }
      })
      .catch(err => {
        console.log('err:', err);
        toast.error("Votre mot de passe n'a pas pu être modifié merci de réessayer");
      });
    // setRedirect(true);
  };

  useEffect(() => {
    setRedirectUrl(`/`);
  }, [setRedirectUrl]);

  useEffect(() => {
    if (password === '' || confirmPassword === '') return setIsDisabled(true);

    setIsDisabled(password !== confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    if (userCode === '') return setIsCodeDisabled(true);

    setIsCodeDisabled(!userCode);
  }, [userCode]);

  return (
    <>
      {isCodeOK ? (
        <>
          <h3>Réinitialiser votre mot de passe</h3>
          <Form className={classNames('mt-3', { 'text-left': hasLabel })} onSubmit={handleReset}>
            <FormGroup>
              {hasLabel && <Label>Nouveau mot de passe</Label>}
              <Input
                placeholder={!hasLabel ? 'Nouveau mot de passe' : ''}
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                type="password"
              />
            </FormGroup>
            <FormGroup>
              {hasLabel && <Label>Confirmez le nouveau mot de passe</Label>}
              <Input
                placeholder={!hasLabel ? 'Confirmez le nouveau mot de passe' : ''}
                value={confirmPassword}
                onChange={({ target }) => setConfirmPassword(target.value)}
                type="password"
              />
            </FormGroup>
            {loading ? (
              <Button color="primary" block className="mt-3" disabled>
                <Spinner type="grow" size="sm" color="light" />
              </Button>
            ) : (
              <Button color="primary" block className="mt-3" disabled={isDisabled}>
                Réinitialiser mon mot de passe
              </Button>
            )}
          </Form>
        </>
      ) : (
        <>
          <h3>Saisissez le code confidentiel</h3>
          <Form className={classNames('mt-3', { 'text-left': hasLabel })} onSubmit={handleTestCode}>
            <FormGroup>
              {hasLabel && <Label>Code confidentiel reçu par mail</Label>}
              <Input
                placeholder={!hasLabel ? 'Code confidentiel' : ''}
                value={userCode}
                onChange={({ target }) => setUserCode(target.value)}
              />
            </FormGroup>
            <Button color="primary" block className="mt-3" disabled={isCodeDisabled}>
              Tester le code
            </Button>
          </Form>
        </>
      )}
    </>
  );
};

PasswordResetForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

PasswordResetForm.defaultProps = { layout: 'basic', hasLabel: false };

export default withRedirect(PasswordResetForm);
